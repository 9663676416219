import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {axiosGet, axiosPost} from "../Utility/httpClient";
import {PlayerDetails, TrackedSource} from "../API/api";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#1b1d27 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

export type LandingProps = {
    userDetails: PlayerDetails | undefined
};

export const Landing: React.FC<LandingProps> = ({ userDetails }) => {
    const classes = useStyles();

    if (!userDetails) {
        return (<>
            <div className="App-contents">
                <div className="Landing-content">
                </div>
            </div>
        </>)
    }

    return (<>
        <div className="App-contents">
            <div className="Landing-content">
                <div className="Search-bar">
                    <div className="Bar-content">
                        <svg id="Layer_1" width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path
                                d="M8.5 3a5.5 5.5 0 0 1 4.38 8.82l4.15 4.15a.75.75 0 0 1-.98 1.13l-.08-.07-4.15-4.15A5.5 5.5 0 1 1 8.5 3Zm0 1.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                                fill="currentColor"/>
                        </svg>
                        <p>Search for anything...</p>
                    </div>
                </div>
                <div className="Landing-heading Heading-Landing">
                    <h2 className="Main-heading">Manhattan Project</h2>
                    <div className="Heading-divider"/>
                    <p className="Main-subheading">Welcome to The Manhattan Project...</p>
                </div>
                <Footer/>
            </div>
        </div>
    </>)
}