import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {makeStyles} from "@material-ui/core/styles";
import {useTableStyles} from "./CasinoWagersTable";
import {fiatWithCommas, numberWithCommas} from "../Utility/utils";

interface Column {
    id: 'username' | 'wagers' | 'totalWagered';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: any) => any;
}

const columns: readonly Column[] = [
    { id: 'username', label: 'Name', minWidth: 100, format: (v: any) => {
            return v ?? 'N/A '
        }},
    { id: 'wagers', label: 'Bets', minWidth: 100, format: (v: any) => {
            return v ? numberWithCommas(v) : 'N/A '
        }},
    { id: 'totalWagered', label : 'Total Wagered', minWidth: 100, format: (v: any) => {
            return '$' + fiatWithCommas(v)
        }},
];

export type CasinoUsersProps = {
    rows: Array<any>,
    setUser: any,
};

export const CasinoUsersTable: React.FC<CasinoUsersProps> = ({
    rows, setUser,
}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const classes = useTableStyles();
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', background: 'transparent' }}>
            <TableContainer sx={{ maxHeight: 840 }}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead
                        className={classes.tableHeader}
                    >
                        <TableRow className={classes.tableRow}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    className={classes.headerCell}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <span>{column.label}</span>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow className={classes.tableRow} hover role="checkbox" onClick={() => setUser(row)} tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];

                                            return (
                                                <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                                                    <span>{// @ts-ignore
                                                        column.format(value)}</span>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                className={"HuntsPagination"}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}