import React from "react";

export type FooterProps = {};

export const Footer: React.FC<FooterProps> = () => {

    return (<footer className="App-footer">
        <div className="Footer-Main">
            <div className="Footer-socials">
                <ul></ul>
            </div>
            <div className="Footer-navigation">
                <ul>
                    <li><a href="https://playerhub.casa/privacy-policy"><span>Privacy Policy</span></a></li>
                    <li><a href="https://playerhub.casa/faq"><span>FAQs</span></a></li>
                    <li><a href="https://playerhub.casa/tos"><span>Terms of Service</span></a></li>
                </ul>
            </div>
        </div>
        <div className="Footer-Signature">
            <span>Manhattan Project | All Right Reserved 2024.</span>
        </div>
    </footer>);

}