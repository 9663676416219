import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import '../Assets/CSS/Admin.scss';
import {Casino, Player, PlayerDetails, TrackedSource} from "../API/api";
import { PieChart, Pie, Sector } from 'recharts';
import {axiosGet} from "../Utility/httpClient";
import {makeStyles} from "@material-ui/core/styles";
import {CasinoUsersTable} from "../Components/CasinoUsersTable";
import {CasinoWagersTable} from "../Components/CasinoWagersTable";
import FeaturedInfo from "../Components/FeaturedInfo";
import Chart from "../Components/Chart";
import {Visibility} from "@material-ui/icons";
import {fiatWithCommas, numberWithCommas} from "../Utility/utils";

export const userData = [
    {
        name: "Jan",
        "Active Users": 4000,
    },
    {
        name: "Feb",
        "Active Users": 3000,
    },
    {
        name: "Mar",
        "Active Users": 5000,
    },
    {
        name: "Apr",
        "Active Users": 4000,
    },
    {
        name: "May",
        "Active Users": 3000,
    },
    {
        name: "Jun",
        "Active Users": 2000,
    },
    {
        name: "Jul",
        "Active Users": 4000,
    },
    {
        name: "Agu",
        "Active Users": 3000,
    },
    {
        name: "Sep",
        "Active Users": 4000,
    },
    {
        name: "Oct",
        "Active Users": 1000,
    },
    {
        name: "Nov",
        "Active Users": 4000,
    },
    {
        name: "Dec",
        "Active Users": 3000,
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#202429 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
    chip: {
        color: 'white !important',
        background: '#121418 !important',
        backgroundColor: '#121418 !important',
        border: '1px solid #2a2e38 !important',
        cursor: 'pointer !important'
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    chipSelected: {
        backgroundSize: 'cover',
        height: '50px',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
        cursor: 'pointer !important',
        backgroundColor: '#080808',
        border: '3px solid #886CFF !important',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            color: '#acacac',
            background: '#121418'
        },
    },
};

export const dialogStyles = {
    backgroundColor: '#1a1d23',
    color: 'white',
    padding: '25px',
    boxShadow: 'none',
};

const getSource = async (uuid: string): Promise<Casino> => axiosGet(`/admin/source/${uuid}`)
const getSources = async (): Promise<Array<TrackedSource>> => axiosGet(`/admin/sources`)

export type CasinosProps = {
    userDetails: PlayerDetails | undefined
};

export const DARK_COLOURS = ['#4C85B1', '#831C44', '#03C888', '#9EACDD'];
export const LIGHT_COLOURS = ['#5173bf', '#42bea5', '#ddae38', '#ba1033'];
export const LIGHT_COLOURS_INCOME_CHART = ["#448aff", "#ff9800"];
export const BAR_CHART_COLOURS_INCOME_CHART = ["#9575cd", "#448aff", "#ff9800"]

const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, name, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                Games
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{name}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};

export const Casinos: React.FC<CasinosProps> = ({ userDetails }) => {

    const [sources, setSources] = React.useState<Array<TrackedSource>>([])

    useEffect(() => {
        if (userDetails) {
            getSources().then(r => setSources(r))
        }
    }, [userDetails])

    const AllCasinosView = () => {
        const classes = useStyles();
        return (<div>

            <div className="Section-heading">
                <h4>Casinos</h4>
            </div>
            <div className="CasinoList">
                {
                    sources.map(s => {
                        return (<a href={"/casinos/source/"+s.id}><div className="CasinoEntry">
                                <img src={s.profileImage}/>
                                <h2>{s.name}</h2>
                        </div></a>)
                    })
                }
            </div>

        </div>);
    }

    function getView(user: Player | undefined, source: Casino | undefined, activeIndex: number, setActiveIndex: any, setUser: any): JSX.Element {

        const userMetrics: Array<any> = [
            {key: 'Username', value: user?.username},
            {key: 'Bets Tracked', value: numberWithCommas(user?.wagers ?? 0)},
            {key: 'Total Wagered', value: '$' + fiatWithCommas(user?.totalWagered ?? 0)},
            {key: 'Total Earnings', value: '$' + fiatWithCommas(user?.totalEarned ?? 0)},
        ]

        const sourceMetrics: Array<any> = [
            {key: 'Users Tracked', value: numberWithCommas(source?.users.length ?? 0)},
            {key: 'Bets Tracked', value: numberWithCommas(source?.bets.length ?? 0)},
            {key: 'Total Wagered', value: '$' + fiatWithCommas(source?.wagered ?? 0)},
            {key: 'Total Paid Out', value: '$' + fiatWithCommas(source?.payout ?? 0)},
            {key: 'Profit / Loss', value: '$' + fiatWithCommas(source?.profit ?? 0)},
        ]

        if (user) {
            return (<>
                <div className="Metrics-container">
                    <FeaturedInfo metrics={user ? userMetrics : sourceMetrics} />
                    <div className="Landing-heading Landing-heading-left">
                        <h4>{user.username + '\'s Bets'}</h4>
                    </div>
                    <CasinoWagersTable rows={source ? source.bets.filter(b => b.user == user.username) : []} user={user} />
                </div>
            </>)
        }
        return (<>
            <div className="Metrics-container">
                <FeaturedInfo metrics={user ? userMetrics : sourceMetrics} />
            </div>

            <div className="homeWidgets">
                <div className="widgetSm" style={{minWidth: '500px'}}>
                    <span className="widgetSmTitle">Top Games</span>
                    <div className='pie-chart'>
                        <PieChart width={600} height={400}>
                            <Pie
                                activeIndex={activeIndex}
                                activeShape={renderActiveShape}
                                data={source!.topGames}
                                cx="38%"
                                cy="50%"
                                innerRadius={85}
                                outerRadius={100}
                                fill={LIGHT_COLOURS[0]}
                                dataKey="value"
                                // onMouseEnter={this.onPieEnter}
                            />
                        </PieChart>
                    </div>
                </div>
                <div className="chart-container">
                    <Chart data={source ? source.playerCount : []} title="User Activity" grid={true} dataKey="count"/>
                </div>
            </div>


            <div className="homeWidgets">
                <div className="widgetSm">
                    <span className="widgetSmTitle">Top Gamblers</span>
                    <ul className="widgetSmList">
                        {

                            source!.topGamblers.map((gambler, idx) => {
                                return (<li className="widgetSmListItem">
                                    <img
                                        src="https://images.pexels.com/photos/3992656/pexels-photo-3992656.png?auto=compress&cs=tinysrgb&dpr=2&w=500"
                                        alt=""
                                        className="widgetSmImg"
                                    />
                                    <div className="widgetSmUser">
                                        <span className="widgetSmUsername">{gambler.name}</span>
                                        <span className="widgetSmUserTitle">${fiatWithCommas(gambler.value)}</span>
                                    </div>
                                    <button className="widgetSmButton" onClick={() => {
                                        setUser(source!.users.find(u => u.username == gambler.name))
                                    }}>
                                        <Visibility className="widgetSmIcon" />
                                        View
                                    </button>
                                </li>)
                            })
                        }
                    </ul>
                </div>

                <div className="widgetSm">
                    <span className="widgetSmTitle">Top Games</span>
                    <ul className="widgetSmList">
                        {
                            source!.topGames.map((game, idx) => {
                                return (<li className="widgetSmListItem">
                                    <img
                                        src="https://images.pexels.com/photos/3992656/pexels-photo-3992656.png?auto=compress&cs=tinysrgb&dpr=2&w=500"
                                        alt=""
                                        className="widgetSmImg"
                                    />
                                    <div className="widgetSmUser">
                                        <span className="widgetSmUsername">{game.name}</span>
                                        <span className="widgetSmUserTitle">{fiatWithCommas(game.value?? 0)}%</span>
                                    </div>
                                    <button className="widgetSmButton" onClick={() => setActiveIndex(idx)}>
                                        <Visibility className="widgetSmIcon" />
                                        Display
                                    </button>
                                </li>)
                            })
                        }

                    </ul>
                </div>

                <div className="widgetSm">
                    <span className="widgetSmTitle">Placeholder...</span>
                    <ul className="widgetSmList">
                    </ul>
                </div>
            </div>
            <div className="homeWidgets">
                <div className="widgetLg" style={{  minWidth: '100%', padding: '10px'}}>
                    <h3 className="widgetLgTitle" style={{  margin: '10px 0'}}>Players</h3>
                    <CasinoUsersTable rows={source ? source.users : []} setUser={setUser} />
                </div>
            </div>
            <br /><br /><br /><br />
        </>)
    }

    const CasinoView = (id: string, sources: Array<TrackedSource>) => {
        const classes = useStyles();

        const [source, setSource] = React.useState<Casino|undefined>(undefined)
        const [user, setUser] = React.useState<Player|undefined>(undefined)
        const [activeIndex, setActiveIndex] = React.useState<number>(0)

        useEffect(() => {
            getSource(id).then(r => setSource(r))
        }, [])

        if (source) {
            return (<div>
                <div className="Section-heading">
                    <h4>{sources.find(s => s.id == id)?.name}</h4>
                </div>
                {getView(user, source, activeIndex, setActiveIndex, setUser)}
            </div>);
        }
        return (<div>

            <div className="Section-heading">
                <h4>Loading {sources.find(s => s.id == id)?.name}...</h4>
            </div>

        </div>);
    }

    const params = useParams();
    const page = params.page
    const model = params.model

    if (!page) {
        window.location.href = '/casinos/home'
    }

    if (model) {
        return (<>
            <div className="App-contents">
                    <div className="Landing-content">
                {CasinoView(model, sources)}
                    </div>
            </div>
        </>)
    }
    return (<>
        <div className="App-contents">
            <div className="Landing-content">
            {AllCasinosView()}
            </div>
        </div>
    </>)
}