import React, {useEffect, useState} from 'react';
import './Assets/CSS/App.scss';
import './Assets/CSS/App.responsive.scss';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Landing} from "./Routes/Landing";
import {Header} from "./Components/Header";
import LoginModal from "./Components/LoginModal";
import {axiosGet} from "./Utility/httpClient";
import {PlayerDetails} from "./API/api";
import {Casinos} from "./Routes/Casinos";

const getDetails = async (): Promise<PlayerDetails> => axiosGet(`/admin/details`)

export default function App() {
    const [open, setOpen] = React.useState<boolean>(true)
    const [details, setDetails] = React.useState<PlayerDetails | undefined>(undefined)

    const hasToken = localStorage.getItem("bearer")

    useEffect(() => {
        if (hasToken) {
            getDetails().then(r => setDetails(r)).catch(e => localStorage.removeItem("bearer"))
        }
    }, [])

    if (!hasToken) {
        return (
            <div className="App">
                <Header userDetails={details} togglePanelVisible={() => {
                }}/>
                <div className="App-contents-container">
                    <BrowserRouter>
                        <Routes>
                            <Route element={<Landing userDetails={details} />} path="/"/>
                        </Routes>
                    </BrowserRouter>
                </div>

                <LoginModal
                    open={open}
                    onClose={() => setOpen(false)}
                />
            </div>
        );
    }

    return (
        <div className="App">
            <Header userDetails={details} togglePanelVisible={() => {}} />
            <div className="App-contents-container">
                <BrowserRouter>
                    <Routes>
                        <Route element={<Landing userDetails={details} />} path="/"/>
                        <Route element={<Casinos userDetails={details} />} path="/casinos/:page/:model?" />
                    </Routes>
                </BrowserRouter>
            </div>
        </div>
    );
}

