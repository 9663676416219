import {
  LineChart,
  Line,
  Area,
  AreaChart,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer, YAxis,
} from "recharts";
import moment from "moment";
import {numberWithCommas} from "../Utility/utils";

// @ts-ignore
function Chart({ title, data, dataKey, grid }) {


  const formatXAxis = (tickItem: Date) => { return moment.utc(tickItem).format("DD-MM-yyyy: hh:mm:ss") }
  // @ts-ignore
  return (
    <div className="chart">
      <h3 className="chartTitle">{title}</h3>
      {/*<ResponsiveContainer width="100%" aspect={2 / 1}>*/}
      {/*  <LineChart data={data}>*/}
      {/*    <XAxis dataKey="time" stroke="#5550bd" tickFormatter={formatXAxis} />*/}
      {/*    <YAxis*/}
      {/*        // axisLine={false}*/}
      {/*        // tickLine={false}*/}
      {/*        // orientation={'right'}*/}
      {/*        tickCount={5}*/}
      {/*        stroke={"#000"}*/}
      {/*        fontSize={11}*/}
      {/*    />*/}
      {/*    <Line type="monotone" dataKey={dataKey  } stroke="#5550bd" />*/}
      {/*    <Tooltip content={*/}
      {/*      //@ts-ignore*/}
      {/*      <CustomTooltip/>*/}
      {/*    } cursor={{fill: 'none'}} position={*/}
      {/*      {}*/}
      {/*      // {y: -50,}*/}
      {/*    }/>*/}
      {/*    {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />}*/}
      {/*  </LineChart>*/}
      {/*</ResponsiveContainer>*/}
      <ResponsiveContainer width="100%" height={400} className="Chart">
        <AreaChart
            // width={500}
            // height={400}
            data={data}
            margin={{
              // top: 10, right: 30, left: 0, bottom: 0,
            }}
        >
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#1DB954" stopOpacity={0.4} />
              <stop offset="100%" stopColor="#1DB954" stopOpacity={0.05} />
            </linearGradient>
          </defs>

          <CartesianGrid strokeDasharray="3 3" vertical={false}  />
          <XAxis dataKey="time" tick={{ fill: 'white' }}  tickFormatter={tick => {
            return new Date(tick).toString().substr(4, 6);
          }} />
          <YAxis tick={{ fill: 'white' }}  />
          <Tooltip content={
            //@ts-ignore
            <CustomTooltip />
          } />
          <Area type="monotone" dataKey={dataKey} stroke="#1DB954" fill="url(#color)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}


// @ts-ignore
const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
        <div className="ChartTooltip">
          <ul className="Headings">
            <li>
              <p>Time</p>
            </li>
            <li>
              <p>Users Online</p>
            </li>
          </ul>
          <ul className="Values">
            <li>
              <p>{new Date(label).toString().substr(16, 8)}</p>
            </li>
            <li>
              <p>{payload[0] ? numberWithCommas(payload[0].value) : ''}</p>
            </li>
          </ul>
        </div>
    );
  }

  return null;
};

export default Chart;